import React from "react";
import logo_2 from "../../assets/img/logo/logo2.png";
import logo_mobile from "../../assets/img/logo/logo-mobile.png";
import dashboard from "../../assets/img/dashboard.png";
import add_new_plumber from "../../assets/img/add_new_plumber.png";
import pending_for_approval from "../../assets/img/pending_for_approval.png";
import approval_plumber from "../../assets/img/approved_plumber.png";
import rejected_plumber from "../../assets/img/rejected_plumber.png";
import report from "../../assets/img/report.png";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
export default function HeaderSales() {
  const logout = () => {
    localStorage.clear();
    // window.location = '/'
  };

  // setTimeout(function () {
  //     localStorage.clear();
  // }, 20 * 60 * 1000); // 20 minutes in milliseconds

  const userType = localStorage.getItem("user_type");
  return (
    <>
      <header>
        <div className="header-container header-sticky sticky">
          <div className="header-top-area">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-6 col-xs-12 text-left">
                  <div className="header-top-menu">
                    <ul className="timing">
                      <li className="">
                        <Link to="https://mail.google.com" target="_blank">
                          <i className="fa fa-envelope"></i>{" "}
                          info@cerastarplumber.com{" "}
                        </Link>
                      </li>
                      <li>
                        <Link>
                          <i className="fa fa-clock-o"></i> Mon - Fri 09.30 AM -
                          5.30 PM{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12 text-right">
                  <div className="header-top-menu">
                    <ul className="timing">
                      <li className="support hidden-xs">
                        <Link>
                          <i className="fa fa-phone"></i>18002026182
                        </Link>
                      </li>
                      <li className="support">
                        <span>
                          <i className="fa fa-user"></i>{" "}
                          {localStorage.getItem("user_name")}
                          <span className="name_text"> </span>
                        </span>
                      </li>
                      <li className="account">
                        <Link to="/" className="login" onClick={logout}>
                          <i className="fa fa-sign-in"></i> Log Out{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-middel-area hidden-xs">
            <div className="container">
              <div className="row">
                <div className="col-md-2 col-sm-2 col-xs-6">
                  <div className="logo logo1">
                    <Link to="/sales_dashboard">
                      <img src={logo_2} alt="" />
                    </Link>
                  </div>
                </div>
                <div className="col-md-10 col-sm-10 col-xs-12 hidden-xs">
                  <div className="main-menu-area">
                    <nav>
                      <ul className="main-menu">
                        <li>
                          <Link to="/sales_dashboard">
                            <img src={dashboard} alt="" /> Performance
                          </Link>
                        </li>
                        {userType === "HO" ||
                        userType === "RO" ||
                        userType === "SH" ? (
                          ""
                        ) : (
                          <li>
                            <Link to="/add_new_plumber">
                              <img src={add_new_plumber} alt="" /> Add New
                              Plumber{" "}
                            </Link>
                          </li>
                        )}
                        <li>
                          <Link to="/pending_for_approval">
                            <img src={pending_for_approval} alt="" /> Plumber
                            Approval Pending
                          </Link>
                        </li>
                        {/* 
                           <li>
                              <Link to="/pending_for_approval">
                              <img src={pending_for_approval} alt="" /> Pending for Approval </Link>
                           </li>
                           */}
                        <li>
                          <Link to="/approved_plumber">
                            <img src={approval_plumber} alt="" /> Approved
                            Plumber{" "}
                          </Link>
                        </li>
                        <li>
                          <Link to="/rejected_plumber">
                            <img src={rejected_plumber} alt="" /> Rejected
                            Plumbers{" "}
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <img src={report} alt="" /> Reports{" "}
                            <i className="fa fa-angle-down"></i>
                          </Link>
                          <ul className="dropdown">
                            <li>
                              <Link to="/sales_enrollment_report">
                                Enrollment Report
                              </Link>
                            </li>
                            {/* <li>
                                             <Link to="/sales_login_report">
                                                Login Report</Link>
                                          </li> */}
                            {/* {userType === "RO" || userType === "SH" ? ( */}
                              <li>
                                <Link to="/sales_qr_transaction_report">
                                  QR Transaction Report
                                </Link>
                              </li>
                            {/* ) : (
                              ""
                            )} */}

                            <li>
                              <Link to="/sales_error_qr_transaction_report">
                                Error QR Transaction Report
                              </Link>
                            </li>
                            {/* 
                                 <li>
                                    <Link to="/sms_report">
                                    SMS Report</Link>
                                 </li>
                                 */}
                            <li>
                              <Link to="/sales_top_performance_report">
                                Top Performers Report
                              </Link>
                            </li>
                            <li>
                              <Link to="/sales_redemption_report">
                                Redemption Report
                              </Link>
                            </li>
                            {/* <li>
                                             <Link to="/sales_inactive_report">
                                                Inactive Report</Link>
                                          </li> */}
                            <li>
                              <Link to="/sales_ticket_report">
                                Ticket Report
                              </Link>
                            </li>
                            {/* <li>
                                             <Link to="/sales_product_wise_report">
                                                Product wise report</Link>
                                          </li> */}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-menu-area hidden-sm hidden-md hidden-lg">
            <div className="container">
              <div className="row">
                {/* 
               <div className="mobile_logo">
                  <Link to="#">
                  <img src={logo_mobile} alt="" /></Link>
               </div>
               */}
                <div className="col-xs-12">
                  <div className="mobile-menu">
                    <nav>
                      <ul className="main-menu">
                        <li>
                          <Link to="/sales_dashboard">
                            <img src={dashboard} alt="" /> Performance
                          </Link>
                        </li>
                        {userType === "HO" ||
                        userType === "RO" ||
                        userType === "SH" ? (
                          ""
                        ) : (
                          <li>
                            <Link to="/add_new_plumber">
                              <img src={add_new_plumber} alt="" /> Add New
                              Plumber{" "}
                            </Link>
                          </li>
                        )}
                        <li>
                          <Link to="/pending_for_approval">
                            <img src={pending_for_approval} alt="" /> Plumber
                            Approval Pending
                          </Link>
                        </li>
                        <li>
                          <Link to="/approved_plumber">
                            <img src={approval_plumber} alt="" /> Approved
                            Plumber{" "}
                          </Link>
                        </li>
                        <li>
                          <Link to="/rejected_plumber">
                            <img src={rejected_plumber} alt="" /> Rejected
                            Plumbers{" "}
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <img src={report} alt="" /> Reports{" "}
                            <i className="fa fa-angle-down"></i>
                          </Link>
                          <ul className="dropdown">
                            <li>
                              <Link to="/sales_enrollment_report">
                                Enrollment Report
                              </Link>
                            </li>
                            {/* <li>
                                             <Link to="/sales_login_report">
                                                Login Report</Link>
                                          </li> */}
                            {/* {userType === "RO" || userType === "SH" ? ( */}
                              <li>
                                <Link to="/sales_qr_transaction_report">
                                  QR Transaction Report
                                </Link>
                              </li>
                            {/* ) : (
                              ""
                            )} */}

                            {/* <li>
                                             <Link to="/sales_error_qr_transaction_report">
                                                Error QR Transaction Report</Link>
                                          </li> */}
                            {/* 
                                 <li>
                                    <Link to="/sms_report">
                                    SMS Report</Link>
                                 </li>
                                 */}
                            {/* <li>
                                             <Link to="/sales_top_performance_report">
                                                Top Performers Report</Link>
                                          </li> */}
                            <li>
                              <Link to="/sales_redemption_report">
                                Redemption Report
                              </Link>
                            </li>
                            {/* <li>
                                             <Link to="/sales_inactive_report">
                                                Inactive Report</Link>
                                          </li> */}
                            {/* <li>
                                             <Link to="/sales_ticket_report">
                                                Ticket Report</Link>
                                          </li> */}
                            {/* <li>
                                             <Link to="/sales_product_wise_report">
                                                Product wise report</Link>
                                          </li> */}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="col-xs-12">
                  <div className="mobile-view-menu">
                    <Navbar expand="lg" className="bg-body-tertiary">
                      <Navbar.Brand href="#home">
                        <Link to="#">
                          <img src={logo_mobile} alt="" />
                        </Link>
                      </Navbar.Brand>
                      <Navbar.Toggle aria-controls="basic-navbar-nav" />
                      <Navbar.Collapse id="basic-navbar-nav">
                        <nav>
                          <ul className="main-menu">
                            <li>
                              <Link to="/sales_dashboard">
                                <img src={dashboard} alt="" />
                                Performance
                              </Link>
                            </li>
                            {userType === "HO" ||
                            userType === "RO" ||
                            userType === "SH" ? (
                              ""
                            ) : (
                              <li>
                                <Link to="/add_new_plumber">
                                  <img src={add_new_plumber} alt="" />
                                  Add New Plumber{" "}
                                </Link>
                              </li>
                            )}
                            <li>
                              <Link to="/pending_for_approval">
                                {" "}
                                <img src={pending_for_approval} alt="" />
                                Plumber Approval Pending{" "}
                              </Link>
                            </li>
                            {/* <li>
                                             <NavDropdown title="Pending Plumber" id="basic-nav-dropdown">Pending for Approval
                                                <NavDropdown.Item href="/pending_for_approval">Pending for Approval</NavDropdown.Item>
                                                <NavDropdown.Item href="/pending_for_admin"> Pending at Admin</NavDropdown.Item>
                                             </NavDropdown>
                                          </li> */}
                            <li>
                              <Link to="/approved_plumber">
                                <img src={approval_plumber} alt="" /> Approved
                                Plumber{" "}
                              </Link>
                            </li>
                            <li>
                              <Link to="/rejected_plumber">
                                <img src={rejected_plumber} alt="" />
                                Rejected Plumbers{" "}
                              </Link>
                            </li>
                            <li>
                              <NavDropdown
                                title="Reports"
                                id="basic-nav-dropdown"
                              >
                                <NavDropdown.Item href="/sales_enrollment_report">
                                  Enrollment Report
                                </NavDropdown.Item>
                                {/* <NavDropdown.Item href="/sales_login_report">Login Report</NavDropdown.Item> */}
                                {/* {userType === "SH" || userType === "RO" ? ( */}
                                  <NavDropdown.Item href="/sales_qr_transaction_report">
                                    QR Transaction Report
                                  </NavDropdown.Item>
                                {/* ) : (
                                  ""
                                )} */}

                                {/* <NavDropdown.Item href="/sales_error_qr_transaction_report">
                                                   Error QR Transaction Report
                                                </NavDropdown.Item> */}

                                {/* <NavDropdown.Item href="/sales_top_performance_report">
                                                   Top Performers Report
                                                </NavDropdown.Item> */}

                                <NavDropdown.Item href="/sales_redemption_report">
                                  Redemption Report
                                </NavDropdown.Item>

                                {/* <NavDropdown.Item href="/sales_inactive_report">
                                                   Inactive Report
                                                </NavDropdown.Item> */}
                                {/* 
                                                <NavDropdown.Item href="/sales_ticket_report">
                                                   Ticket Report
                                                </NavDropdown.Item> */}

                                {/* <NavDropdown.Item href="/sales_product_wise_report">
                                                   Product wise report
                                                </NavDropdown.Item> */}
                              </NavDropdown>
                            </li>
                          </ul>
                        </nav>
                      </Navbar.Collapse>
                    </Navbar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
